import {Component} from "react";
import {values} from "mobx";
import VerticalBlock from "../blocks/TerracedGridTheme/Terrace";

import classNames from "classnames";
import styles from "./styles/StaticGrid.module.scss";
import {AppContextType, StoreContext} from "../../stores/StoreLoader";
import {observer} from "mobx-react";

const pageBodyClassName = classNames({
    [styles.pageBody]: true,
});


@observer
class TerracedGrid extends Component {
    static contextType = StoreContext;

    constructor(props, context) {
        super(props);
    }

    handleClick = () => {
        const {modalStore} = this.context as AppContextType;
        modalStore.addModal({type: "terraceManagementModal"})
    }

    render() {
        const {gridStore, userStore} = this.context as AppContextType;

        const content = values(gridStore.blocks);

        return <main className={pageBodyClassName} role="main" id={'main'}>
            {content.length === 0 && userStore.isEditor ? <div className={styles.noContent}>
                You haven't added any terraces yet!
                <div>
                    <button onClick={this.handleClick}>Add One Now</button>
                </div>
            </div> : content.filter(blockObj => blockObj.type !== "hero")
                .map((blockObj, index) => {
                    return <VerticalBlock
                        key={blockObj.id}
                        blockObj={blockObj}
                        aboveTheFold={index === 0}
                    />
                })}
        </main>
    }
}

export default TerracedGrid
