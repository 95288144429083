import {useContext, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {reaction} from "mobx";

import classNames from "classnames";
import styles from "./styles/_BlockCS.module.scss";

import dynamic from 'next/dynamic';
import ErrorBlock from "../SchoolBlocks/ErrorBlock";
import {StoreContext} from "../../../stores/StoreLoader";
import WatsonApi from "../../../backends/WatsonApi";

export const updateContentItem = async (newContentItem: Partial<ContentItem>, newOrganization: BaseOrganization) => {
    const client = await WatsonApi();
    const response = await client.apis.organizations.organizations_content_items_partial_update({
        organization_pk: newContentItem.organization_id,
        id: newContentItem.id,
        data: {
            ...newContentItem,
            organization_id: newOrganization.id,
        },
    });
    return JSON.parse(response.data);
}
export const deleteContentItem = async (contentItem) => {
    const client = await WatsonApi();
    await client.apis.organizations.organizations_content_items_delete({
        id: contentItem.id,
        organization_pk: contentItem.organization_id,
    });
}

export const enum ITerraceTypes {
    EVENTS = "events",
    NEWS = "news",
    MEDIA = "media",
    ABOUT = "about",
    MAP = "map",
    HTML = "html",
    FILES = "files",
    HERO = "hero",
    LINKS = "links",
    STAFF = "staff",
    STATEMENT = "statement",
    SHORTCUTS = "shortcuts",
    SHOP = "shop",
    ACCORDION = "accordion",
    TILES = "tiles",
}

const blockTypes = {
    [ITerraceTypes.MAP]: dynamic(() => import("./MapBlock/MapBlock")),
    [ITerraceTypes.EVENTS]: dynamic(() => import("./EventsBlock/EventsBlock")),
    [ITerraceTypes.ABOUT]: dynamic(() => import("./AboutBlock/AboutBlock")),
    [ITerraceTypes.HTML]: dynamic(() => import("./HTMLBlock/HTMLBlock")),
    [ITerraceTypes.NEWS]: dynamic(() => import("./NewsBlock/NewsBlock")),
    [ITerraceTypes.MEDIA]: dynamic(() => import("./GalleryBlock/GalleryBlock")),
    [ITerraceTypes.FILES]: dynamic(() => import("./FilesFoldersBlock/FilesFoldersBlock")),
    [ITerraceTypes.LINKS]: dynamic(() => import("./LinksTerrace/LinksTerrace")),
    [ITerraceTypes.STAFF]: dynamic(() => import("./StaffBlock/StaffBlock")),
    [ITerraceTypes.STATEMENT]: dynamic(() => import("./StatementBlock/StatementBlock")),
    [ITerraceTypes.SHORTCUTS]: dynamic(() => import("./ShortcutsTerrace/ShortcutsTerrace")),
    [ITerraceTypes.SHOP]: dynamic(() => import("./ShopTerrace/ShopTerrace")),
    [ITerraceTypes.ACCORDION]: dynamic(() => import("./AccordionTerrace/AccordionTerrace")),
};

export type ITerraceProps = {
    aboveTheFold: boolean,
    handleUpdateContentItem: (contentItem: ContentItem, organization: BaseOrganization) => void,
    handleDeleteContentItem: (contentItem: ContentItem) => void,
}

const Block = observer((props: {
    blockObj: ITerrace,
    aboveTheFold: boolean,
}) => {
    const {interfaceStore, gridStore, organizationStore, modalStore} = useContext(StoreContext);

    const [aboveTheFold, setAboveTheFold] = useState(props.aboveTheFold);
    const blockRef = useRef<HTMLElement | null>(null);

    const BlockTypeComponent = blockTypes[props.blockObj.type] || ErrorBlock;

    useEffect(() => {
        const r = reaction(() => interfaceStore.scrollPosition, () => {
            if (blockRef?.current && !aboveTheFold) {
                const rect = blockRef.current?.getBoundingClientRect();
                if (rect && rect.top < (window.innerHeight - (window.innerHeight/4)) && !aboveTheFold) {
                    setAboveTheFold(true);
                }
            }
        }, {fireImmediately: true})
        return () => r();
    }, []);

    async function handleDeleteContentItem(contentItem) {
        await deleteContentItem(contentItem);
        modalStore.clearAllModals();
        gridStore.setBlocks([{
            ...props.blockObj,
            content_items: props.blockObj.content_items?.filter(c => c.id !== contentItem.id),
        }]);
    }

    const blockClassName = classNames({
        [styles.block]: true,
        [styles.blockAboveTheFold]: aboveTheFold,
    });

    return (
        <article
            className={blockClassName}
            data-id={props.blockObj.id}
            data-block-type={props.blockObj.type}
            ref={blockRef}
        >
            <BlockTypeComponent
                blockObj={props.blockObj}
                aboveTheFold={aboveTheFold}
                handleDeleteContentItem={handleDeleteContentItem}
            />
        </article>
    );
});

export const terraceTypeToTitleMap: {[value: string]: string} = {
    [ITerraceTypes.HERO]: "Hero Image",
    [ITerraceTypes.ABOUT]: "About",
    [ITerraceTypes.NEWS]: "News",
    [ITerraceTypes.EVENTS]: "Events",
    [ITerraceTypes.LINKS]: "Links",
    [ITerraceTypes.MEDIA]: "Gallery",
    [ITerraceTypes.FILES]: "Files & Folders",
    [ITerraceTypes.STAFF]: "Staff",
    [ITerraceTypes.HTML]: "HTML",
    [ITerraceTypes.MAP]: "Map",
    [ITerraceTypes.STATEMENT]: "Statement",
    [ITerraceTypes.SHORTCUTS]: "Shortcuts",
    [ITerraceTypes.SHOP]: "Shop",
    [ITerraceTypes.ACCORDION]: "Accordion",
}

export default Block;
