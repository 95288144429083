import {useEffect} from "react";
import {useRollbar} from "@rollbar/react";

export default function ErrorBlock(props: IErrorBlockComponentProps): JSX.Element {
    // const rollbar = useRollbar();

    let msg: string;
    if (typeof props.blockObj.errorMessage === "string") {
        msg = props.blockObj.errorMessage;
    } else if (props.blockObj.errorMessage instanceof Error) {
        msg = props.blockObj.errorMessage.message
    } else {
        msg = 'Some unidentified error occurred';
    }

    useEffect(() => {
        // if errorMessage is a string, we probably manually passed in an error message to render the ErrorBlock
        // and it's not really a caught error that should be reported
        if (typeof props.blockObj.errorMessage === "object") {
            // rollbar.error(props.blockObj.errorMessage);
        }
    }, []);

    return <div className="sb-blockContent sb-error-inside-block">
        <span className="sb-error-inside-block-icon far fa-frown"/>
        <div className="sb-error-inside-block-header"><h2>Aww, snap!</h2></div>
        <div className="sb-error-inside-block-message" dangerouslySetInnerHTML={{__html: msg}} />
    </div>
}
